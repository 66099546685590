document.addEventListener('DOMContentLoaded', () => {
  const leadForm = document.querySelector('#lead-form'),
        consentForm = document.querySelector('#consent-form'),
        product = document.querySelector('#lead_product'),
        age = document.querySelector('#lead_age_of_youngest_homeowner');
  if (leadForm) {
    console.log('lead form on page');
    leadForm.addEventListener('ajax:success', (e) => {
      console.log('lead success', e);
      leadForm.innerHTML = 'Your lead has been submitted successfully.';
    });
    leadForm.addEventListener('ajax:error', (e) => {
      console.log('lead failure', e);
      alert('Something went wrong with the server, please try again');
    });
    // age only required for reverse product
    product.addEventListener('change', () => {
      if (product.selectedIndex == 1) {
        age.setAttribute('required', 'true');
        age.closest('.form-group').classList.add('required');
      } else {
        age.removeAttribute('required');
        age.closest('.form-group').classList.remove('required');
      }
    });
  }
  if (consentForm) {
    consentForm.addEventListener('ajax:success', (e) => {
      console.log('consent success', e);
      consentForm.innerHTML = '<p>We have received your acknowledgement and will soon be in touch</p>';
    });
    consentForm.addEventListener('ajax:error', (e) => {
      console.log('consent failure', e);
      alert('Something went wrong with the server, please try again');
    });
  }
});